@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  overflow: hidden;
}
.products {
  width: 70vw;
  [class*="slick-list"] {
    overflow: visible;
  }
  [class*="slick-arrow"] {
    width: 36px;
    height: 36px;
    z-index: 10;
    background-size: 83%;
    background-position: center;
    background-repeat: no-repeat;
    top: 40vw;
    background-color: #fff;
    border-radius: 50%;
    &:before {
      display: none;
    }
  }
  [class*="slick-next"] {
    right: -27vw;
    background-image: url("/static/images/icons/arrow-slider-right.png");
  }
  [class*="slick-prev"] {
    left: 30px;
    background-image: url("/static/images/icons/arrow-slider-left.png");
  }
  > div {
    width: 100%;
  }
  .slide {
    padding: 0 10px;
    .type {
      position: absolute;
      z-index: 10;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-color: variable.$primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-family: "Freeman";
      font-size: variable.$size14;
      left: 15px;
      top: 15px;
      line-height: 16px;
      padding-bottom: 4px;
      box-shadow: -3px 2px 11px rgba(0, 0, 0, 0.25);
      &.T {
        background-color: #a5d396;
        color: variable.$primaryColor;
        padding-bottom: 0;
      }
    }
    > div {
      position: relative;
      background-color: #eee;
    }
    .title {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 10;
      color: variable.$whiteColor;
      font-size: 4.5vw;
      padding: 0 10px;
    }
    .thumbnail {
      > div {
        padding-top: 132%;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
        }
      }
      img {
        position: absolute;
        inset: 0;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .products {
    width: auto;
    [class*="slick-arrow"] {
      top: 50%;
    }
    [class*="slick-next"] {
      right: 20px;
    }
    .slide {
      width: auto !important;
      .type {
        width: 85px;
        height: 85px;
        font-size: variable.$size16;
      }
      .thumbnail {
        width: 265px;
      }
      .title {
        font-size: variable.$size20;
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .products {
    .slide {
      .title {
        bottom: 30px;
      }
      .thumbnail {
        width: 386px;
      }
    }
  }
}
